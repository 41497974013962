<template>
  <div class="py-4">
    <h2 class="font-serif text-center">Loading preview...</h2>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'
  import Prismic from 'prismic-javascript'
  import store from '@/store'

  export default {
    name: 'Preview',
    beforeRouteEnter (to, _from, next) {
      const { token, documentId } = to.query
      store.dispatch('initPreview', { token, documentId }).then((url) => {
        Cookies.set(Prismic.previewCookie, token, { expires: 1 / 48 })
        next(url)
      }).catch((err) => {
        console.log(err)
        next('/')
      })
    }
  }
</script>
